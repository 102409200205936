.categories
{
    width: 30%;
    ul
    {
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 1200px) {
           flex-direction: column;
        }
        padding: 0;
        list-style-type: none;
        li
        {
            width: 50%;
            font-size: 1.2rem;
            margin-bottom: 12px;
            button
            {
                padding:8px 12px 8px 12px;
                min-width: 11rem;
                text-align: left;
                background-color: #edf2f4;
                border-radius: 5px;
            }
        }
    }
}
.card-title
{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}