/*===================
    Section Title 
====================*/

.section-title {
    margin-top: 30px;
    h2{
        &.title{
            font-size: 60px;
            margin-bottom: 8px;
            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$sm-layout} {
                font-size: 36px;
                margin-bottom: 7px;
            }
            &.newTitle
            {
                text-transform: uppercase;
                font-size: 24px;
                color: #086FB8;
                @media #{$laptop-device} {
                    font-size: 45px;
                }
                @media #{$lg-layout} {
                    font-size: 45px;
                }
                @media #{$md-layout} {
                    font-size: 45px;
                }
            }
        }
    }
    p {
        font-size: 1.5rem;
        line-height: 30px;
        color: rgba(29, 29, 36, 0.75);
        a {
            color: rgba(29, 29, 36, 1);
            font-weight: 500;
            &:hover {
                color: $theme-color;
            }
        }
        br {
            @media #{$sm-layout} {
                display: none;    
            }
        }
    }

    h3 {
        font-size: 45px;
        line-height: 48px;
        color:#086FB8;
        text-transform: uppercase;
        font-weight: bold;
        @media #{$sm-layout} {
            font-size: 26px;
            margin-bottom: 8px;
            line-height: 32px;
        }
    }
    .service-btn{
        margin-top: 36px;
        @media #{$sm-layout} {
            margin-top: 12px;
        }
    }

    &.service-style--2 {
        h2 ,
        h3 {
            color: #ffffff;
        }
        p{
            padding: 0 21%;
            font-weight: 300;
            color: #ffffff;
            @media #{$md-layout} {
                padding: 0 7%;
            }
            @media #{$sm-layout} {
                padding: 0;
            }
        }
    }

    &.service-style--3{
        h2 {
            color:#00A4E0;
        }
        p {
            padding: 0 21%;
            font-weight: 300;
            color: #231F20;
            @media #{$md-layout} {
                padding: 0 7%;
            }
            @media #{$sm-layout} {
                padding: 0;
            }
            &.newSubtitle
            {
                font-size: 26px;
                font-weight: bold;
                color: #00A4E0;
                padding:0;
                @media #{$laptop-device} {
                    font-size: 30px;
                }
                @media #{$md-layout} {
                    font-size: 30px;
                }
                @media #{$lg-layout} {
                    font-size: 30px;
                }
            }
        }
        &.text-left {
            p {
                padding: 0 3% 0 0;
            }
        }
    }

}
.section-title-default {
    h2 {
        &.title {
            line-height: 1.34;
            font-size: 48px;
            @extend %fontWeight400;
        }
    }
}


.section-title {
    h2{
        @media #{$sm-layout} {
            font-size: 36px;
            margin-bottom: 7px;
        }
    }
}
h4.newSubtitle
{
        font-size: 26px;
        font-weight: bold;
        color: #00A4E0;
        @media #{$laptop-device} {
            font-size: 30px;
        }
        @media #{$lg-layout} {
            font-size: 30px;
        }
        @media #{$md-layout} {
            font-size: 30px;
        }
        @media #{$sm-layout} {
            font-size: 24px;
        }
        
}