.slider__homepage
{
    height: 500px !important;
    @media #{$small-mobile} {
        height: 200px !important;
      }
}
.img__introduction
{
  padding:200px 0;
  @media #{$small-mobile} {
    padding:150px 0;
  }
}
.img__page_AboutUsPage
{
  padding:80px 0;
  @media #{$small-mobile} {
    padding:50px;
  }

}
.img__page_BRPage
{
  padding:200px 0;
  @media #{$small-mobile} {
    padding:100px 0;
  }
}