.about__us
{
    margin: 20px 0;
    h2
    {
        text-align: center;
        font-size: 22px
    }
    p
    {
        font-size: 16px !important;
        font-weight: 400;
        @media #{$md-layout}
        {
            font-size: 18px !important;
            text-align: center;
        }
        
    }
}
.feedback-area
{
    padding-bottom: 120px;
    margin-bottom: 80px;
    h2.heading
    {
        text-align: center;
        padding-top:20px;
        // margin-bottom: -150px;
        color: white;
        font-size: 2.1rem;
        @media only screen and (min-width: 900px) {
            font-size: 2.4rem;
            text-align: center;
        }
        

    }
    .row .col-12
    {
        .feedback-detail
        {
            border: 10px;
            border-radius: 50px;
            background-color: white;
            display: flex;
            .feedback__detail-client-content
            {
                padding: 20px;
                p{
                    padding:12px;
                    color:black;
                    font-size: 18px;
                }
                q{
                    padding:12px;
                    font-weight: 600;
                }
                @media #{$small-mobile}
                {
                  padding:10px;
                }
            }
        }
    }
}
.card__special {
    margin-top: 20px;
    display: flex;
    border-radius: 50px;
    height: 300px;
    // box-shadow: 1rem 0.5rem 0 0 #74c044;
    // border: 1px solid #74c044;
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
    @media #{$lg-layout} {
      width: 400px;
      height: 300px;
    }
    @media #{$md-layout}
    {
      width: 350px;
      height:300px;
    }
    @media #{$sm-layout}
    {
      width: 340px;
      height:280px;
    }
    @media #{$large-mobile}
    {
      width: 80vw;
      height:300px;
    }
    @media #{$small-mobile}
    {
      width: 80vw;
      margin: auto;
      height:280px;
    }
    p
    {
      -webkit-line-clamp: 5;
      text-align: left;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: break-word;
      white-space:pre-wrap;
      line-height: 36px;
      margin-bottom: -2px;
      padding-bottom: 0 !important;      
    }
  }
  .card__special:not(:first-child) {
    margin-left: 50px;
    @media #{$lg-layout} {
      margin-left: 0;
      margin-top: 50px;
    }
  }

  .card__special:hover {
    transform: translateY(-10px);
    transition: 0.4s ease-out;
  }

  .card__special:hover ~ .card__special {
    position: relative;
    transition: 0.4s ease-out;
  }
  .title__special {
    color: rgb(var(--textTitle));
    font-weight: 600;
    font-size: 16px;
    position: absolute;
    right: 12px;
    bottom: 10px;
    text-align: right;
    width: 250px;
    color:black;
  }
  .bar {
    position: absolute;
    top: 45px;
    left: 80px;
    height: 5px;
    width: 150px;
  }

  .emptybar {
    background-color: #74c044;
    width: 100%;
    height: 100%;
  }

  .filledbar {
    position: absolute;
    top: 0px;
    z-index: 3;
    width: 0px;
    height: 100%;
    background: rgb(0, 154, 217);
    background: linear-gradient(90deg, rgba(0, 154, 217, 1) 0%, rgba(217, 147, 0, 1) 65%, rgba(255, 186, 0, 1) 100%);
    transition: 0.6s ease-out;
  }

  .card__special:hover .filledbar {
    width: 120px;
    transition: 0.4s ease-out;
  }

  .circle {
    // position: absolute;
    // top: 150px;
    // left: calc(50% - 60px);
    padding: 10px;
    width: 62px;
    height: 62px;
  }

  .stroke {
    stroke: #74c044;
    stroke-dasharray: 360;
    stroke-dashoffset: 360;
    transition: 0.6s ease-out;
  }

//   svg {
//     fill: #fff;
//     stroke-width: 2px;
//   }

  .card__special:hover .stroke {
    stroke-dashoffset: 0;
    transition: 0.6s ease-out;
  }
  .description__special {
    position: absolute;
    top: 20px;
    left: 80px;
    font-size: 16px;
    color: #333333;
  }

  .image-wrapper {
    padding: 10px;
    width: 68px;
    height: 68px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
  }

  .image {
    border-radius: 50%;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
  }

  .border-line {
    border: 2px solid transparent;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-color: transparent transparent transparent #74c044;
    animation: border-line-animation 2s infinite;
    transition: 0.6s ease-out;
  }

  @keyframes border-line-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .card:hover .image {
    transform: scale(1.1);
  }
