/*=====================
    Pagination Styles 
=======================*/

.rn-pagination {
  ul {
    &.page-list {
      @extend %liststyle;
      li {
        display: inline-block;
        margin: 5px;
        padding: 0 10px;
        text-align: center;
        line-height: 38px;
        min-width: 42px;
        height: 42px;
        border: 1px solid #0000004d;
        background: #fff;
        color: #000000;
        position: relative;
        z-index: 2;
        font-weight: 600;
        border-radius: 4px;

        &::before {
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          content: "";
          z-index: -1;
          opacity: 0;
          visibility: hidden;
          @extend %transition;
        }
        &:hover {
          &::before {
            opacity: 1;
            visibility: visible;
          }
        }

        &.active {
          &::before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    &.pagination-vn {
      li {
        &::before {
          background: linear-gradient(-259deg, #ffd634 0, #f1c100 100%);
        }
        &.active {
          color: #ffffff;
          background-color: #f1c100;
        }
        &:hover {
          color: #ffffff;
          background-color: #f1c100;
        }
      }
    }
    &.pagination-au {
      li {
        &::before {
          background: linear-gradient(-259deg, #d32118 0, #d50b00 100%);
        }
        &.active {
          color: #ffffff;
          background-color: #d50b00;
        }
        &:hover {
          color: #ffffff;
          background-color: #d50b00;
        }
      }
    }
  }
}

.line-separator {
  border-bottom: 1px solid #ebebeb;
}
