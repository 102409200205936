.business__representative
{
    margin-top: 4px;
    >.row
    {
        flex-direction: column;
        @media only screen and (min-width: 1208px)
        {
            flex-direction: row;
        }
    }
   
    .conclusion
    {
        h2
        {
            font-size:24px;
            font-style: italic;
            text-align: center;
        }
    }
    p
    {
        font-size: 18px;
        line-height: 30px;
        color: rgba(29,29,36,.75);
        text-align: justify !important;
        padding-left: 0;
        @media #{$large-mobile}
        {
            font-size: 15px !important;
            text-align: justify !important;
            padding-right: 0;    
        }
    }
    blockquote p
    {
        @media #{$large-mobile}
        {
            font-size: 15px !important;
            text-align: left !important;   
        }
    }
    button
    {
        background-color: #004aad;
        font-size: 24px;
    }
    ul
    {
        padding-left: 0;
        list-style: disc;
    }
    h2
    {
        font-weight: bold;
        font-size: 2.5rem;
        color:#086FB8;
        @media #{$sm-layout}
        {
            font-size: 2rem;
            text-align: center;
        }
        @media #{$small-mobile}
        {
            font-size: 1.5rem;
            text-align: center;
        }
        
    }
}